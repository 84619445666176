import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from '@reach/router';
import { Card, CardBody, CardHeader, CardFooter } from '@paljs/ui/Card';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import { Toastr, ToastrRef, ToastrProps } from '@paljs/ui/Toastr';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import AsyncSelect from 'react-select/async'
import Fuse from 'fuse.js';
import { Radio } from '@paljs/ui/Radio';
import Select from '../../pages/villages/select-options';
import { Checkbox } from '@paljs/ui/Checkbox';
import { RangeSlider, InputGroup as InputRange, InputNumber } from 'rsuite';
import VillageListOptions from './village-list-options';
import Slider from '@mui/material/Slider';
import { padding } from '@mui/system';
import DateRangePicker from '../../components/date/date-range'
import moment from 'moment';


const Filter = ({ filterAction, max_node_count, max_install_count }) => {
    const [verified, setVerified] = useState('ALL');
    const [latLong, setLatLong] = useState('ALL');
    const [asyncData, setAsyncData] = useState({});
    const [close, setClose] = useState(false);
    const [appInstallCount, setAppInstallCount] = useState<number[]>([0, max_install_count])
    const [nodeCount, setNodeCount] = useState<number[]>([0, max_node_count])
    const [isOpen, setIsOpen] = useState(false);
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')

    const villageListRef = useRef()
    const dateRangeRef = useRef()

    const asyncChanges = (data) => {
        setAsyncData(data);
    }

    const onSubmit = () => {
        filterAction({ ...asyncData, verified, latLong, nodeCount, appInstallCount, startDate, endDate })
    }

    const onReset = () => {
        setStartDate('');
        setEndDate('')
        setVerified('ALL');
        setLatLong('ALL');
        setAsyncData({});
        setClose(!close);
        setNodeCount([0, max_node_count]);
        setAppInstallCount([0, max_install_count]);
        filterAction({
            district: '',
            tehsil: '',
            panchayat: '',
            village: '',
            verified: "ALL",
            latLong: "ALL",
            nodeCount: [0, max_node_count],
            appInstallCount: [0, max_install_count]
        })
        villageListRef.current.clearData();
        dateRangeRef.current.onReset();
    }

    const appInstalledCountSliderChnage = (event: Event, newValue: number | number[]) => {
        setAppInstallCount(newValue)
    }
    const nodeCountSliderChange = (event: Event, newValue: number | number[]) => {
        setNodeCount(newValue)
    }

    const dateRangeChange = (range) => {
        const changedStartDate = moment(range.startDate).format("YYYY-MM-DD");
        const changedEndDate = moment(range.endDate).format("YYYY-MM-DD");
        range.startDate !== range.endDate ? setIsOpen(!isOpen) : '';
        // dateRangeFilterData(changedStartDate, changedEndDate);
        setStartDate(changedStartDate);
        setEndDate(changedEndDate);
    }

    const cardBody = (
        <Row>
            <VillageListOptions onChange={asyncChanges} ref={villageListRef} />
            <p>Verified Village? :</p>
            <Col style={{ display: 'flex' }}>
                <Radio
                    name="Verify"
                    onChange={(evnt) => setVerified(evnt)}
                    options={[
                        {
                            value: 'VERIFIED',
                            label: 'Verified',
                            status: 'Info',
                            checked: verified == "VERIFIED" ? true : false
                        },
                        {
                            value: 'NOT_VERIFIED',
                            label: 'Not Verified',
                            status: 'Danger',
                            checked: verified == "NOT_VERIFIED" ? true : false
                        },
                        {
                            value: verified,
                            label: 'All',
                            checked: true
                        }
                    ]}
                />
            </Col>

            <p>Latitude & Longitude? :</p>
            <Col style={{ display: 'flex' }}>
                <Radio
                    name="Lat/Long"
                    onChange={(evnt) => setLatLong(evnt)}
                    options={[
                        {
                            value: 'LAT_LONG',
                            label: 'Having Latitude & Longitude',
                            status: 'Info'
                        },
                        {
                            value: 'NO_LAT_LONG',
                            label: 'Not Having Latitude & Longitude',
                            status: 'Danger',
                        },
                        {
                            value: 'ALL',
                            label: 'All',
                            checked: true
                        }
                    ]}
                />
            </Col>

        </Row>
    )


    return (
        <>
            <Card status="Info">

                <CardHeader style={{height:'10px', textAlign:'center', display:'flex', alignItems:'center'}}>Filter</CardHeader>

                <CardBody>
                    <Row>
                        <VillageListOptions onChange={asyncChanges} ref={villageListRef} type='creatable' />
                        <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                            <Col style={{ display: 'flex', width: '50%', minWidth: '300px' }}>
                                <p>Verified Village? :</p>
                                <Radio
                                    name="Verify"
                                    onChange={(evnt) => { console.log('event', evnt); setVerified(evnt) }}
                                    options={[
                                        {
                                            value: 'VERIFIED',
                                            label: 'Yes',
                                            status: 'Info',
                                        },
                                        {
                                            value: 'NOT_VERIFIED',
                                            label: 'No',
                                            status: 'Danger',
                                        },
                                        {
                                            value: verified,
                                            label: 'All',
                                            checked: true
                                        }
                                    ]}
                                />
                            </Col>


                            <Col style={{ display: 'flex', width: '50%', minWidth: '300px' }}>
                                <p>Latitude & Longitude? :</p>&nbsp;
                                <Radio
                                    name="Lat/Long"
                                    onChange={(evnt) => setLatLong(evnt)}
                                    options={[
                                        {
                                            value: 'LAT_LONG',
                                            label: 'Yes',
                                            status: 'Info'
                                        },
                                        {
                                            value: 'NO_LAT_LONG',
                                            label: 'No',
                                            status: 'Danger',
                                        },
                                        {
                                            value: 'ALL',
                                            label: 'All',
                                            checked: true
                                        }
                                    ]}
                                />
                            </Col>
                        </div>

                        <Col>
                            <Col breakPoint={{ xs: 12, md: 4 }}>
                                <Button
                                    fullWidth
                                    appearance="outline"
                                    shape={'Round'}
                                    size={'Small'}
                                    Status={'Info'}
                                    onClick={() => {
                                        setIsOpen(!isOpen)
                                    }}
                                >
                                    Select Date Range
                                </Button>
                            </Col>
                            <DateRangePicker
                                onChange= {(range:any)=>{dateRangeChange(range)}}
                                isOpen={isOpen}
                                ref = {dateRangeRef}
                            />
                        </Col>
                        
                        <Col style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                            <Col style={{ width: '470px' }}>
                                <p >Node&nbsp;Counts&nbsp;: ({nodeCount[0]} - {nodeCount[1]})</p>
                                <Slider
                                    getAriaLabel={() => 'Temperature range'}
                                    value={nodeCount}
                                    onChange={nodeCountSliderChange}
                                    valueLabelDisplay="auto"
                                    min={0}
                                    max={max_node_count}
                                />
                            </Col>

                            <Col style={{ width: '470px' }}>
                                <p >App Install&nbsp;Counts&nbsp;: ({appInstallCount[0]} - {appInstallCount[1]})</p>
                                <Slider
                                    getAriaLabel={() => 'Temperature range'}
                                    value={appInstallCount}
                                    onChange={appInstalledCountSliderChnage}
                                    valueLabelDisplay="auto"
                                    min={0}
                                    max={max_install_count}
                                />
                            </Col>
                        </Col>

                    </Row>


                </CardBody >
                <CardFooter>
                    <Row style={{ display: 'flex', justifyContent: 'center', }}>
                        <Button status="Success" type="button" shape="SemiRound" size='Tiny'
                            style={{ margin: '5px', display: 'block' }}
                            onClick={onSubmit}
                        >
                            submit
                        </Button>
                        <Button status="Danger" type="button" shape="SemiRound"
                            style={{ margin: '5px', display: 'block' }}
                            onClick={() => onReset()}
                        >
                            Reset
                        </Button>
                    </Row>
                </CardFooter>
            </Card>
        </>
    )
}
export default Filter;
